html {
  box-sizing: border-box;
  font-size: 16px;
  word-break: keep-all;
  color: #333e48;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Apple SD Gothic Neo', Dotum, Arial, sans-serif;
  line-height: 1.618;
  -webkit-font-smoothing: antialiased;
}

body.spoqa-han-sans-loaded {
  font-family: 'Spoqa Han Sans', 'Apple SD Gothic Neo', Dotum, Arial, sans-serif;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.25rem;
  font-weight: normal;
}
h3 {
  font-size: 1.125rem;
  font-weight: normal;
}

a {
  color: currentColor;
  text-decoration: none;
}

table {
  border-collapse: collapse;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Mobile__Body--open {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
